import * as React from 'react';
import styles from './style.module.css';



export default function Banner() {
    const isUAT = process.env.REACT_APP_ENV === 'uat';
    const isDev = process.env.REACT_APP_ENV === 'test';
    const isShow = isUAT || isDev;

    const title = isUAT ? "User Acceptance Testing (UAT)" : isDev ? "Development Environment (Dev)" : "Local Environment"; 

    const backgroundColor = isUAT ? "#26dd1528" : isDev ? "#f8e91628" : "#f8e91608"; 
    const color = isUAT ? "#3eb311" : isDev ? "#e6e610" : "#f8e91618"; 

    return (
        <div className={styles.banner}  style={{
                zIndex: isShow ? 97 : -1,
                backgroundColor: backgroundColor,
                color: color
            }}>
            <h1>{title}</h1>
        </div>
    );
}