import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar';
import ComingSoon from './ComingSoon';
import Dashboard from './Dashboard/dashboard';
import Forecast from './Forecast/Forecast';
import Error403 from './Error/403/403';
import Error401 from './Error/401/401';
import Error404 from './Error/404/404';
import Help from './Help/Help';
import Settings from './Settings/Settings';
import Topbar from '../components/TopBar/Topbar';
import { useAppDispatch, useAppSelector } from '../stores/hooks';
import { setDimensions } from '../stores/Slices/topbarSlice';
import { usePermission } from 'react-permission-role';
import Banner from '../components/Banner/Banner';

export interface IAppProps {
    // children: React.ReactNode
}

function Page(props: IAppProps) {

    const isMobile = useAppSelector(state => state.topbar.isMobile);
    const user = useAppSelector(state => state.user);
    const dispatch = useAppDispatch();
    const { setUser: setUserPerms } = usePermission();

    // set up permissions if the user is already logged in
    // but the change was deployed after them logging in
    // this is usually handled in the login part see `AuthContext`
    useEffect(() => {
        setUserPerms({
            id: user.userID,
            roles: user.role
        })
    }, [])

    function handleWindowSizeChange() {
        // we let redux handle the state here for width and height change, 
        // use isMobile state in topbar slice if the device is mobile
        // the logic for defining that is in topbar slice
        dispatch(setDimensions({ width: window.innerWidth, height: window.innerHeight }))
    }

    useEffect(() => {

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const navbarOpen = useAppSelector(state => state.navbar.navbarOpen);

    return (
        <>
            {
                isMobile ? <Topbar /> : <Navbar />
            }
            <Banner />
            <div style={
                isMobile
                    ? { flexGrow: 1, paddingTop: "40px" }
                    : { marginLeft: navbarOpen ? '250px' : '79px', transition: "margin-left 0s ease-out", padding: "0px" }}>
                <PageRoutes />
            </div>
        </>


    );
}

const PageRoutes = () => {

    return (
        <Routes>
            <Route path='*' element={<Error404 />} />
            <Route path='dashboard/*' element={<Dashboard />} />
            <Route path='goField/*' element={<Forecast key="test112312" />} />
            <Route path='news/*' element={<ComingSoon />} />
            <Route path='record/*' element={<ComingSoon />} />
            <Route path='history/*' element={<ComingSoon />} />
            <Route path='help/*' element={<Help />} />
            <Route path='invoices/*' element={<ComingSoon />} />
            <Route path='settings/*' element={<Settings />} />
            <Route path='401' element={<Error401 />} />
            <Route path='403' element={<Error403 />} />
            <Route path='404' element={<Error404 />} />
        </Routes>
    );
}

export default Page;